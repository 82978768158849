/* You can add global styles to this file, and also import other style files */
@import '@ctrl/ngx-emoji-mart/picker';
@import "~@ng-select/ng-select/themes/default.theme.css";

* {
  font-family: 'Wix Madefor Text', 'Helvetica Neue', Helvetica, Arial,
    sans-serif !important;
}

st-editor .st-toolbar * {
  font-family: 'Font Awesome 5 Free', serif !important;
}

body {
  height: 100svh;
}

@media (max-width: 550px),
(max-width: 500px),
(max-width: 450px),
(max-width: 400px),
(max-width: 360px),
(max-width: 280px) {
  .modal-fullscreen .modal-content {
    display: flex;
    height: 100svh;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.pac-container {
  z-index: 100000 !important;
}

.button-load-spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-left-color: #ffffff;
  animation: spin 1s ease infinite;
  width: 20px;
  height: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*FONTES TEXT
  texto-style-size-weight
  se a cor do texto for preto ele vai terminar com -p
*/
.text-normal-14-400 {
  font-size: 0.875rem;
  /* 14px em rem */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-normal-16-400 {
  font-size: 1rem;
  /* 16px em rem */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-normal-16-500 {
  font-size: 1rem;
  /* 16px em rem */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-normal-16-600 {
  font-size: 1rem;
  /* 16px em rem */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-normal-16-700 {
  font-size: 1rem;
  /* 16px em rem */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-normal-20-500 {
  text-align: center;

  font-size: 1.25rem;
  /* 20px em rem */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-normal-24-500 {
  font-size: 1.5rem;
  /* 24px em rem */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-normal-24-600 {
  font-size: 1.5rem;
  /* 24px em rem */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-normal-32-700 {
  font-size: 2rem;
  /* 32px em rem */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-normal-40-700 {
  font-size: 2.5rem;
  /* 40px em rem */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/*FONTES ESPECIAIS - usadas sempre para a mesma coisa*/
.label-text-inicio {
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.label-text-form {
  color: #262626;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-reenviar-codigo {
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
}

/*CORES BASTANTE USADAS*/

.text-grey {
  color: #5c5c5c;
}

.text-light-grey {
  color: #b7b4bb;
}

.text-dark-blue {
  color: #224189;
}

.text-purple {
  color: #007bff;
}

/*layout comuns para form*/

.form-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 1rem;
}

.shadow-box .form-div label {
  width: 100%;
}

.shadow-box .form-div {
  align-items: center;
}

.form-input {
  display: flex;
  width: 100%;
  padding: 9px 12px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--colors-neutrals-neutral-light, #e5e5e5);
  background: var(--colors-neutrals-neutral-xx-light, #fff);
}

.shadow-box {
  max-height: 95%;
  display: flex;
  padding: 5rem 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 45px;

  border-radius: 14px;
  border-top: 1px solid rgba(217, 217, 217, 0.54);
  border-left: 1px solid rgba(217, 217, 217, 0.54);
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(4px);
}

/*STYLE BUTTON*/

.button-form,
.button-roxo-form,
.button-transparente-roxo-form {
  display: flex;
  width: 21rem;
  overflow: hidden;
  padding: 1rem 0;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
}

.button-form {
  border-top: 1px solid #6599f3;
  border-left: 1px solid #6599f3;
  box-shadow: 0 0 0.5rem rgba(119, 233, 255, 0.8);
  background: #497dd7;
}

.button-roxo-form {
  border: 1px solid rgba(156, 106, 245, 0.8);
  background: linear-gradient(90deg, #7133df 1.89%, #b388ff 114.08%);
  box-shadow: 0 0 1rem rgba(119, 233, 255, 0.8);
}

.button-roxo-form:disabled {
  background: #b7b4bb;
  box-shadow: none;
  border: none;
}

.button-transparente-roxo-form {
  border: 0.125rem solid #007bff;
}

.error-message {
  color: red !important;
  font-size: 14px;
}

.pointer {
  cursor: pointer;
}

.grow-on-hover {
  transition: transform 0.3s ease;
}

.grow-on-hover:hover {
  transform: scale(1.1);
}

.alert-div {
  position: fixed;
  width: 100%;
  /* O contêiner ocupa toda a largura */
  top: 11%;
  z-index: 9999 !important;
  display: flex;
  justify-content: center;
  /* Centraliza horizontalmente o conteúdo */
  align-items: center;
}

ngb-alert {
  width: 100%;
  /* O alert ocupa toda a largura do contêiner */
  max-width: 50%;
  /* Limite a largura máxima para evitar que fique muito grande */
  text-align: center;
  /* Centraliza o texto dentro do alert */
}

@media (max-width: 768px) {
  .shadow-box {
    width: 100%;
    padding: 2rem;
    margin-top: 20px;
  }
}

.bi-arrow-left {
  font-size: 1.5rem;
  height: fit-content;
  color: #224189;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 20px;
}

.bi-arrow-left:hover {
  color: #1a326d;
  transform: scale(1.2);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.bi-arrow-left:active {
  transform: scale(1.1);
  color: #14224f;
}

.bi-arrow-left.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.count-items {
  display: flex;
  justify-content: end;
}

.count-items span {
  color: gray;
  padding: 2px 8px;
  font-size: 14px;
}

.toast-top-center {
  top: 100px !important;
  /* Ajuste o valor conforme necessário */
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-bottom-align {
  display: none !important;
}

.mdc-tooltip__surface {
  background-color: #007bff !important;
  color: #ffffff !important;
  font-size: 14px !important;
  border-radius: 8px !important;
  padding: 8px !important;
  margin-top: 10px !important;
}

.mdc-tooltip__surface-animation {
  animation: none !important;
}

/* .mat-mdc-tooltip {
  background-color: #7C29D9 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  border-radius: 8px !important;
  padding: 8px !important;
  margin-top: 10px !important;
} */

/* .mdc-tooltip__surface .mdc-tooltip__surface-animation {
  color: orange !important;
} */
.md-drppicker.ltr,
.md-drppicker {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: none !important;
  z-index: 1 !important;
}

.md-drppicker>* {
  flex: 1 1 100% !important;
  min-width: 0 !important;
}

.md-drppicker .buttons_input {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 30px !important;
  gap: 1rem;
}

.md-drppicker .buttons_input .clear svg {
  display: none !important;
}

.md-drppicker .buttons_input .btn {
  padding: 10px !important;
  border-radius: 8px !important;
  background-color: #007bff !important;
  color: #ffffff !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  line-height: 13px !important;
  text-align: center !important;
  border: none !important;
  transition:
    background-color 0.3s ease,
    opacity 0.3s ease !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19.36px !important;
  text-align: center !important;
}

.md-drppicker .buttons_input .btn-default {
  color: #007bff !important;
  background-color: transparent !important;
  border: none !important;
  padding: 10px !important;
  text-transform: capitalize !important;
}

.md-drppicker .calendar.right {
  display: none !important;
}

.md-drppicker .calendar.left {
  width: 100% !important;
}

.md-drppicker .calendar {
  max-width: 100% !important;
}

.personalized-scroll {}

.personalized-scroll::-webkit-scrollbar {
  width: 6px;
}

.personalized-scroll::-webkit-scrollbar-thumb {
  background-color: #58a5f7;
  border-radius: 10px;
}

.personalized-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #9b29d9;
}

apx-chart,
apx-chart svg,
apx-chart {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
}

/*IMAGE CROP*/

.file-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image-upload-label {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  background: #f4f4f4;
  width: 100%;
  height: 300px;
  border: 1px solid #cdc9c9;
  cursor: pointer;
  border-radius: 8px;
}

.image-upload-label:hover {
  border-color: #858585;
}

.image-upload-label:hover>i {
  color: #858585;
}

.image-upload-label>i {
  font-size: 36px;
  color: #cdc9c9;
}

.image-upload-label span {
  font-size: 14px;
  color: #323232;
}

.image-upload {
  display: none;
}